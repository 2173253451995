<template>
    <div class="div-main">
        <Loading v-if="loading" type="line" :center="false" />
        <div v-if="frames.length == 0 && !loading">
            Nenhum quadro encontrado!
        </div>
        <div v-else class="side-by-side" v-for="frame in frames">
            <div class="molded-frame">
                <div class="icon-frame">
                    <div class="side-by-side">
                        <div class="frame-name">
                            {{ frame.name }}
                        </div>
                    </div>
                </div>
                <div class="div-allowed">
                    <div v-html="frame.allowedUsers"></div>
                </div>
                <div class="div-btn">
                    <div class="side-by-side icon-btn" @click="navegateTo(frame)">
                        <i class="fa-solid fa-chart-kanban"></i>
                    </div>
                    <div class="side-by-side icon-btn" @click="update(frame)">
                        <i class="fa-solid fa-pen-to-square"></i>
                    </div>
                    <div class="side-by-side icon-btn icon-trash" @click="confirmDelete(frame)">
                        <i class="fa-solid fa-trash"></i>
                    </div>
                </div>
            </div>
        </div>
        <Modal title="Tem certeza que deseja excluir?" :width="500" v-if="showModal('confirmDelete')">
            <div class="text-center">
                <Button _key="btnRemove" type="danger" title="Sim, confirmar" size="medium" :clicked="remove" />
            </div>
        </Modal>
    </div>
</template>
<script>

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
    name: "FrameList",
    components: {
        Modal, Button, Loading
    },
    data() {
        return {
            urlGetAll: "/api/v1/planner/frame/get-all",
            urlDeleteAllApi: "/api/v1/planner/frame/delete",
            frames: [],
            frameSelected: {},
            loading: true,
        }
    },
    created() {
        this.getAll();
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapGetters("generic", ["event", "showModal"]),
    },
    methods: {
        ...mapActions("generic", ["getApi", "deleteAllApi"]),
        ...mapMutations("generic", ["addEvent", "openModal", "hideModal", "removeLoading"]),
        getAll() {
            let params = { url: this.urlGetAll, obj: { any: this.userLogged.user.id } };
            this.getApi(params).then((response) => {
                this.frames = response.content.data;
                this.loading = false;
            });
        },
        confirmDelete(frame) {
            this.frameSelected = frame;
            this.openModal("confirmDelete");
        },
        update(frame) {
            this.addEvent({ name: "frameUpdate", data: frame });
        },
        remove() {
            let params = {
                url: this.urlDeleteAllApi,
                selected: [this.frameSelected.id],
            };

            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.hideModal("confirmDelete");
                    this.getAll();
                }
                this.removeLoading(["btnRemove"]);
            });
        },
        navegateTo(frame) {
            this.$router.push({
                name: "kanbanList",
                params: { id: frame.id, name: frame.name }
            });
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "frameCreated" || event.name == "frameUpdated") {
                    this.getAll();
                }
            },
            deep: true,
        },
    },
};
</script>

<style style>
.div-main {
    margin-top: 40px;
}

.molded-frame {
    border: 1px solid #e8eaed;
    background-color: white;
    border-radius: 10px !important;
    width: 320px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.icon-frame {
    font-size: 18px;
}

.frame-name {
    margin-bottom: -10px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.div-description {
    font-size: 12px;
    padding-bottom: 50px;
}

.div-user-frame {
    margin-left: -10px;
}

.img-user-frame {
    width: 25px;
    height: 25px;
    border-radius: 100px;
}

.div-allowed {
    margin-left: 10px;
}

.div-btn {
    margin-top: 15px;
    margin-bottom: 10px;

}

.icon-btn {
    margin-right: 20px;
    font-size: 17px;
    cursor: pointer;
    color: grey;
}

.icon-btn:hover {
    color: black;
}


.icon-trash {
    color: red;
}

.icon-trash:hover {
    color: rgb(245, 92, 92) !important;
}
</style>