<template>
    <div>
        <Panel module="Quadro" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
            <div slot="content-main">
                <br />
                <Button _key="btnFrameCreate" title="Quadro" classIcon="fas fa-plus-circle" type="primary" size="small"
                    :clicked="create" />
                <FrameList />
            </div>
        </Panel>
        <Modal title="Quadro" :width="750" :height="750" v-show="showModal('frame')">
            <FrameCreateUpdate />
        </Modal>
    </div>
</template>

<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import FrameList from '../../../components/modules/planner/frame/FrameList.vue'
import FrameCreateUpdate from '../../../components/modules/planner/frame/FrameCreateUpdate.vue'

import { mapMutations, mapGetters } from "vuex";

export default {
    name: "FrameView",
    components: { Panel, Loading, Button, Modal, FrameCreateUpdate, FrameList },
    data() {
        return {

        }
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
        create() {
            this.openModal("frame");
            this.addEvent({ name: "frameCreate" });
            this.removeLoading(["btnFrameCreate"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "frameUpdate") {
                    this.openModal("frame");
                }
            },
            deep: true,
        },
    },
}
</script>
<style scoped></style>