<template>
  <div>
    <b-row>
      <b-col sm="5">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="40" v-model="frame.name"
          :markFormDirty="false" />
      </b-col>
      <b-col sm="7">
        <InputText title="Descrição" field="description" :formName="formName" :maxLength="40" v-model="frame.description"
          :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <RadioGroup title="Canal de Comunicação" field="channel" :options="options" v-model="frame.channel" />
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="Permissão">
        <CheckboxSimple title="Compartilhado com a Equipe" v-model="frame.shared" />
        <div v-if="!frame.shared">
          <b-row>
            <b-col sm="6">
              <Select title="Usuário" :showNewRegister="false" field="user" url="/api/v1/adm/user/select-all"
                v-model="selectUser" />
            </b-col>
            <b-col sm="4">
              <div class="btn-add">
                <Button _key="btnAddUser" type="primary" :disabled="!selectUser.id" classIcon="fa-solid fa-plus"
                  size="small" :clicked="addUser" />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <InputTag title="Permitidos" :initialValue="frame.allowedUsers" v-model="frame.allowedUsers" />
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
    <br>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="saveFrame" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveFrame" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";

import Frame from "@/components/modules/planner/frame/Frame.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "FrameCreateUpdate",
  components: { Button, InputText, InputTag, Select, CheckboxSimple, RadioGroup },
  data() {
    return {
      formName: "frameCreateUpdate",
      frame: new Frame(),
      urlCreate: "/api/v1/planner/frame/create",
      urlUpdate: "/api/v1/planner/frame/update",
      selectUser: {},
      options: [{ text: 'CRM', value: 1 }, { text: 'Financeiro', value: 3 }, { text: 'Suporte', value: 2 }, { text: 'Nenhum', value: 0 }]
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveFrame() {
      this.frame.userId = this.userLogged.user.id;
      if (this.frame.id) {
        let params = { url: this.urlUpdate, obj: this.frame };
        this.putApi(params).then((response) => {
          if (response.success) {
            this.addEvent({ name: "frameUpdated" });
            this.hideModal();
          }
          this.removeLoading(["saveFrame"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.frame };
        this.postApi(params).then((response) => {
          if (response.success) {
            this.addEvent({ name: "frameCreated" });
            this.hideModal();
          }
          this.removeLoading(["saveFrame"]);
        });
      }
    },
    addUser() {
      if (!this.frame.allowedUsers.includes(this.selectUser.content)) {
        this.frame.allowedUsers.push(this.selectUser.content);
        this.selectUser = {};
      }
      this.removeLoading(["btnAddUser"]);
    },
    update(data) {
      this.frame = new Frame();
      this.frame.update(data);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "frameCreate") {
          this.resetValidation(this.formName);
          this.frame = new Frame();
        }
        if (event.name == "frameUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.btn-add {
  margin-top: 38px;
}
</style>
